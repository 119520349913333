import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";

export default class ContactIndexPage extends React.Component {
  render() {
    return (
      <Layout isContact={true}>
        <PageHeader title={"Contact"} paintColor={"purple"} />
      </Layout>
    );
  }
}
